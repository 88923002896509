export var gradeList = [
    {
        "gradeName": "一年级",
        "gradeCover": require("../assets/gradecover/1.png"),
        "gradeBooks": [
            {
                "bookName": "《咏鹅》",
                "bookImg": require("../assets/poetry/grade1/咏鹅.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%80%E5%B9%B4%E7%BA%A7/%E5%92%8F%E9%B9%85%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
            {
                "bookName": "《画》",
                "bookImg": require("../assets/poetry/grade1/画.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%80%E5%B9%B4%E7%BA%A7/%E7%94%BB%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
            {
                "bookName": "《江南》",
                "bookImg": require("../assets/poetry/grade1/江南.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%80%E5%B9%B4%E7%BA%A7/%E6%B1%9F%E5%8D%97%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
            {
                "bookName": "《悯农》（其二）",
                "bookImg": require("../assets/poetry/grade1/悯农（其二）.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%80%E5%B9%B4%E7%BA%A7/%E6%82%AF%E5%86%9C%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
            {
                "bookName": "《古朗月行》（节选）",
                "bookImg": require("../assets/poetry/grade1/古朗月行.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%80%E5%B9%B4%E7%BA%A7/%E5%8F%A4%E6%9C%97%E6%9C%88%E8%A1%8C%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
            {
                "bookName": "《风》",
                "bookImg": require("../assets/poetry/grade1/风.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%80%E5%B9%B4%E7%BA%A7/%E9%A3%8E%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
            {
                "bookName": "《春晓》",
                "bookImg": require("../assets/poetry/grade1/春晓.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%80%E5%B9%B4%E7%BA%A7/%E6%98%A5%E6%99%93%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
            {
                "bookName": "《赠汪伦》",
                "bookImg": require("../assets/poetry/grade1/赠汪伦.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%80%E5%B9%B4%E7%BA%A7/%E8%B5%A0%E6%B1%AA%E4%BC%A6%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
            {
                "bookName": "《静夜思》",
                "bookImg": require("../assets/poetry/grade1/静夜思.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%80%E5%B9%B4%E7%BA%A7/%E9%9D%99%E5%A4%9C%E6%80%9D%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
            {
                "bookName": "《寻隐者不遇》",
                "bookImg": require("../assets/poetry/grade1/寻隐者不遇.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%80%E5%B9%B4%E7%BA%A7/%E5%AF%BB%E9%9A%90%E8%80%85%E4%B8%8D%E9%81%87%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
            {
                "bookName": "《池上》",
                "bookImg": require("../assets/poetry/grade1/池上.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%80%E5%B9%B4%E7%BA%A7/%E6%B1%A0%E4%B8%8A%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
            {
                "bookName": "《小池》",
                "bookImg": require("../assets/poetry/grade1/小池.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%80%E5%B9%B4%E7%BA%A7/%E5%B0%8F%E6%B1%A0%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
            {
                "bookName": "《画鸡》",
                "bookImg": require("../assets/poetry/grade1/画鸡.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%80%E5%B9%B4%E7%BA%A7/%E7%94%BB%E9%B8%A1%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
        ]
    },
    {
        "gradeName": "二年级",
        "gradeCover": require("../assets/gradecover/2.png"),
        "gradeBooks": [
            {
                "bookName": "《梅花》",
                "bookImg": require("../assets/poetry/grade2/梅花.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E6%A2%85%E8%8A%B1.mp4",
            },
            {
                "bookName": "《小儿垂钓》",
                "bookImg": require("../assets/poetry/grade2/小儿垂钓.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E5%B0%8F%E5%84%BF%E5%9E%82%E9%92%93.mp4",
            },
            {
                "bookName": "《登鹳雀楼》",
                "bookImg": require("../assets/poetry/grade2/登鹳雀楼.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E7%99%BB%E9%B9%B3%E9%9B%80%E6%A5%BC.mp4",
            },
            {
                "bookName": "《望庐山瀑布》",
                "bookImg": require("../assets/poetry/grade2/望庐山瀑布.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E6%9C%9B%E5%BA%90%E5%B1%B1%E7%80%91%E5%B8%83.mp4",
            },
            {
                "bookName": "《江雪》",
                "bookImg": require("../assets/poetry/grade2/江雪.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E6%B1%9F%E9%9B%AA.mp4",
            },
            {
                "bookName": "《夜宿山寺》",
                "bookImg": require("../assets/poetry/grade2/夜宿山寺.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E5%A4%9C%E5%AE%BF%E5%B1%B1%E5%AF%BA.mp4",
            },
            {
                "bookName": "《敕勒歌》",
                "bookImg": require("../assets/poetry/grade2/敕勒歌.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E6%95%95%E5%8B%92%E6%AD%8C.mp4",
            },
            {
                "bookName": "《村居》",
                "bookImg": require("../assets/poetry/grade2/村居.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E6%9D%91%E5%B1%85.mp4",
            },
            {
                "bookName": "《咏柳》",
                "bookImg": require("../assets/poetry/grade2/咏柳.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E5%92%8F%E6%9F%B3.mp4",
            },
            {
                "bookName": "《赋得古原草送别》（节选）",
                "bookImg": require("../assets/poetry/grade2/赋得古草原送别.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E8%B5%8B%E5%BE%97%E5%8F%A4%E5%8E%9F%E8%8D%89%E9%80%81%E5%88%AB.mp4",
            },
            {
                "bookName": "《晓出净慈寺送林子方》",
                "bookImg": require("../assets/poetry/grade2/晓出净慈寺送林子方.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E6%99%93%E5%87%BA%E5%87%80%E6%85%88%E5%AF%BA%E9%80%81%E6%9E%97%E5%AD%90%E6%96%B9.mp4",
            },
            {
                "bookName": "《绝句》",
                "bookImg": require("../assets/poetry/grade2/绝句.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E7%BB%9D%E5%8F%A5.mp4",
            },
            {
                "bookName": "《悯农》（其一）",
                "bookImg": require("../assets/poetry/grade2/悯农（其一）.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E6%82%AF%E5%86%9C%E5%85%B6%E4%B8%80.mp4",
            },
            {
                "bookName": "《舟夜书所见》",
                "bookImg": require("../assets/poetry/grade2/舟夜书所见.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%8C%E5%B9%B4%E7%BA%A7/%E8%88%9F%E5%A4%9C%E4%B9%A6%E6%89%80%E8%A7%81.mp4",
            },
        ]
    },
    {
        "gradeName": "三年级",
        "gradeCover": require("../assets/gradecover/3.png"),
        "gradeBooks": [
            {
                "bookName": "《所见》",
                "bookImg": require("../assets/poetry/grade3/所见.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E6%89%80%E8%A7%81.mp4",
            },
            {
                "bookName": "《山行》",
                "bookImg": require("../assets/poetry/grade3/山行.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E5%B1%B1%E8%A1%8C.mp4",
            },
            {
                "bookName": "《赠刘景文》",
                "bookImg": require("../assets/poetry/grade3/赠刘景文.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E8%B5%A0%E5%88%98%E6%99%AF%E6%96%87.mp4",
            },
            {
                "bookName": "《夜书所见》",
                "bookImg": require("../assets/poetry/grade3/夜书所见.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E3%80%8A%E5%A4%9C%E4%B9%A6%E6%89%80%E8%A7%81%E3%80%8B.mp4",
            },
            {
                "bookName": "《望天门山》",
                "bookImg": require("../assets/poetry/grade3/望天门山.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E6%9C%9B%E5%A4%A9%E9%97%A8%E5%B1%B1.mp4",
            },
            {
                "bookName": "《饮湖上初晴后雨》",
                "bookImg": require("../assets/poetry/grade3/饮湖上初晴后雨.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E9%A5%AE%E6%B9%96%E4%B8%8A%E5%88%9D%E6%99%B4%E5%90%8E%E9%9B%A8.mp4",
            },
            {
                "bookName": "《望洞庭》",
                "bookImg": require("../assets/poetry/grade3/望洞庭.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E6%9C%9B%E6%B4%9E%E5%BA%AD.mp4",
            },
            {
                "bookName": "《早发白帝城》",
                "bookImg": require("../assets/poetry/grade3/早发白帝城.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E6%97%A9%E5%8F%91%E7%99%BD%E5%B8%9D%E5%9F%8E.mp4",
            },
            {
                "bookName": "《采莲曲》",
                "bookImg": require("../assets/poetry/grade3/采莲曲.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E9%87%87%E8%8E%B2%E6%9B%B2.mp4",
            },
            {
                "bookName": "《绝句》",
                "bookImg": require("../assets/poetry/grade3/绝句.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E4%B8%89%E5%B9%B4%E7%BA%A7%E7%BB%9D%E5%8F%A5.mp4",
            },
            {
                "bookName": "《惠崇春江晚景》",
                "bookImg": require("../assets/poetry/grade3/崇春江晚景.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E6%83%A0%E5%B4%87%E6%98%A5%E6%B1%9F%E6%99%9A%E6%99%AF.mp4",
            },
            {
                "bookName": "《三衢道中》",
                "bookImg": require("../assets/poetry/grade3/三衢道中.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E4%B8%89%E8%A1%A2%E9%81%93%E4%B8%AD.mp4",
            },
            {
                "bookName": "《忆江南》",
                "bookImg": require("../assets/poetry/grade3/忆江南.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E5%BF%86%E6%B1%9F%E5%8D%97.mp4",
            },
            {
                "bookName": "《元日》",
                "bookImg": require("../assets/poetry/grade3/元日.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E5%85%83%E6%97%A5.mp4",
            },
            {
                "bookName": "《清明》",
                "bookImg": require("../assets/poetry/grade3/清明.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E6%B8%85%E6%98%8E.mp4",
            },
            {
                "bookName": "《九月九日忆山东兄弟》",
                "bookImg": require("../assets/poetry/grade3/九月九一日忆山东兄弟.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E4%B9%9D%E6%9C%88%E4%B9%9D%E6%97%A5%E5%BF%86%E5%B1%B1%E4%B8%9C%E5%85%84%E5%BC%9F.mp4",
            },
            {
                "bookName": "《滁州西涧》",
                "bookImg": require("../assets/poetry/grade3/滁州西涧.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E6%BB%81%E5%B7%9E%E8%A5%BF%E6%B6%A7%E5%AE%8C%E6%95%B4%E7%89%88.mp4",
            },
            {
                "bookName": "《大林寺桃花》",
                "bookImg": require("../assets/poetry/grade3/大林寺桃花.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%B8%89%E5%B9%B4%E7%BA%A7/%E5%A4%A7%E6%9E%97%E5%AF%BA%E6%A1%83%E8%8A%B1.mp4",
            },
        ]
    },
    {
        "gradeName": "四年级",
        "gradeCover": require("../assets/gradecover/4.png"),
        "gradeBooks": [
            {
                "bookName": "《浪淘沙》",
                "bookImg": require("../assets/poetry/grade4/浪淘沙.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E6%B5%AA%E6%B7%98%E6%B2%99%E5%85%B6%E4%B8%83.mp4",
            },
            {
                "bookName": "《鹿柴》",
                "bookImg": require("../assets/poetry/grade4/鹿柴.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E9%B9%BF%E6%9F%B4.mp4",
            },
            {
                "bookName": "《暮江吟》",
                "bookImg": require("../assets/poetry/grade4/暮江吟.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E6%9A%AE%E6%B1%9F%E5%90%9F.mp4",
            },
            {
                "bookName": "《题西林壁》",
                "bookImg": require("../assets/poetry/grade4/题西林壁.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E9%A2%98%E8%A5%BF%E6%9E%97%E5%A3%81.mp4",
            },
            {
                "bookName": "《雪梅》",
                "bookImg": require("../assets/poetry/grade4/雪梅.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E9%9B%AA%E6%A2%85.mp4",
            },
            {
                "bookName": "《嫦娥》",
                "bookImg": require("../assets/poetry/grade4/嫦娥.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E5%AB%A6%E5%A8%A5.mp4",
            },
            {
                "bookName": "《出塞》",
                "bookImg": require("../assets/poetry/grade4/出塞.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E5%87%BA%E5%A1%9E.mp4",
            },
            {
                "bookName": "《凉州词》",
                "bookImg": require("../assets/poetry/grade4/凉州词.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E5%87%89%E5%B7%9E%E8%AF%8D.mp4",
            },
            {
                "bookName": "《夏日绝句》",
                "bookImg": require("../assets/poetry/grade4/夏日绝句.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E5%A4%8F%E6%97%A5%E7%BB%9D%E5%8F%A5.mp4",
            },
            {
                "bookName": "《别董大》",
                "bookImg": require("../assets/poetry/grade4/别董大.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E5%88%AB%E8%91%A3%E5%A4%A7.mp4",
            },
            {
                "bookName": "《四时田园杂兴》（其二十五）",
                "bookImg": require("../assets/poetry/grade4/四时田园杂兴（其二十五）.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E5%9B%9B%E6%97%B6%E7%94%B0%E5%9B%AD%E6%9D%82%E5%85%B4%EF%BC%88%E5%85%B6%E4%BA%8C%E5%8D%81%E4%BA%94%EF%BC%89.mp4",
            },
            {
                "bookName": "《宿新市徐公店》",
                "bookImg": require("../assets/poetry/grade4/宿新市徐公店.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E5%AE%BF%E6%96%B0%E5%B8%82%E5%BE%90%E5%85%AC%E5%BA%97.mp4",
            },
            {
                "bookName": "《清平乐.村居》",
                "bookImg": require("../assets/poetry/grade4/清平乐村居.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E6%B8%85%E5%B9%B3%E4%B9%90.%E6%9D%91%E5%B1%85.mp4",
            },
            {
                "bookName": "《江畔独步寻花》",
                "bookImg": require("../assets/poetry/grade4/江畔独步寻花.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E6%B1%9F%E7%95%94%E7%8B%AC%E6%AD%A5%E5%AF%BB%E8%8A%B1.mp4",
            },
            {
                "bookName": "《蜂》",
                "bookImg": require("../assets/poetry/grade4/蜂.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E8%9C%82.mp4",
            },
            {
                "bookName": "《独坐敬亭山》",
                "bookImg": require("../assets/poetry/grade4/独坐敬亭山.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E7%8B%AC%E5%9D%90%E6%95%AC%E4%BA%AD%E5%B1%B1.mp4",
            },
            {
                "bookName": "《芙蓉楼送辛渐》",
                "bookImg": require("../assets/poetry/grade4/芙蓉楼送辛渐.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E8%8A%99%E8%93%89%E6%A5%BC%E9%80%81%E8%BE%9B%E6%B8%90.mp4",
            },
            {
                "bookName": "《塞下曲》",
                "bookImg": require("../assets/poetry/grade4/塞下曲.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E5%A1%9E%E4%B8%8B%E6%9B%B2.mp4",
            },
            {
                "bookName": "《墨梅》",
                "bookImg": require("../assets/poetry/grade4/墨梅.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E5%A2%A8%E6%A2%85.mp4",
            },
            {
                "bookName": "《卜算子.咏梅》",
                "bookImg": require("../assets/poetry/grade4/卜算子咏梅.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%9B%9B%E5%B9%B4%E7%BA%A7/%E5%8D%9C%E7%AE%97%E5%AD%90.%E5%92%8F%E6%A2%85.mp4",
            },
        ]
    },
    {
        "gradeName": "五年级",
        "gradeCover": require("../assets/gradecover/5.png"),
        "gradeBooks": [
            {
                "bookName": "《蝉》",
                "bookImg": require("../assets/poetry/grade5/蝉.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E8%9D%89.mp4",
            },
            {
                "bookName": "《乞巧》",
                "bookImg": require("../assets/poetry/grade5/乞巧.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E4%B9%9E%E5%B7%A7.mp4",
            },
            {
                "bookName": "《示儿》",
                "bookImg": require("../assets/poetry/grade5/示儿.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E7%A4%BA%E5%84%BF.mp4",
            },
            {
                "bookName": "《题临安邸》",
                "bookImg": require("../assets/poetry/grade5/题临安邸.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E9%A2%98%E4%B8%B4%E5%AE%89%E9%82%B8.mp4",
            },
            {
                "bookName": "《己亥杂诗》",
                "bookImg": require("../assets/poetry/grade5/己亥杂诗.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E5%B7%B1%E4%BA%A5%E6%9D%82%E8%AF%97.mp4",
            },
            {
                "bookName": "《枫桥夜泊》",
                "bookImg": require("../assets/poetry/grade5/枫桥夜泊.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E6%9E%AB%E6%A1%A5%E5%A4%9C%E6%B3%8A.mp4",
            },
            {
                "bookName": "《山居秋暝》",
                "bookImg": require("../assets/poetry/grade5/山居秋暝.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E5%B1%B1%E5%B1%85%E7%A7%8B%E6%9A%9D.mp4",
            },
            {
                "bookName": "《长相思》",
                "bookImg": require("../assets/poetry/grade5/长相思.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E9%95%BF%E7%9B%B8%E6%80%9D.mp4",
            },
            {
                "bookName": "《渔歌子》",
                "bookImg": require("../assets/poetry/grade5/渔歌子.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E6%B8%94%E6%AD%8C%E5%AD%90.mp4",
            },
            {
                "bookName": "《观书有感》（其一）",
                "bookImg": require("../assets/poetry/grade5/观书有感（其一.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E8%A7%82%E4%B9%A6%E6%9C%89%E6%84%9F%EF%BC%88%E5%85%B6%E4%B8%80%EF%BC%89.mp4",
            },
            {
                "bookName": "《观书有感》（其二）",
                "bookImg": require("../assets/poetry/grade5/观书有感（其二).jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E8%A7%82%E4%B9%A6%E6%9C%89%E6%84%9F%EF%BC%88%E5%85%B6%E4%BA%8C%EF%BC%89.mp4",
            },
            {
                "bookName": "《四时田园杂兴》（其三十一）",
                "bookImg": require("../assets/poetry/grade5/四时田园杂兴31.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E5%9B%9B%E6%97%B6%E7%94%B0%E5%9B%AD%E6%9D%82%E5%85%B4-%E5%85%B6%E4%B8%89%E5%8D%81%E4%B8%80.mp4",
            },
            {
                "bookName": "《稚子弄冰》",
                "bookImg": require("../assets/poetry/grade5/稚子弄冰.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E7%A8%9A%E5%AD%90%E5%BC%84%E5%86%B0.mp4",
            },
            {
                "bookName": "《村晚》",
                "bookImg": require("../assets/poetry/grade5/村晚.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E6%9D%91%E6%99%9A.mp4",
            },
            {
                "bookName": "《游子吟》",
                "bookImg": require("../assets/poetry/grade5/游子吟.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E6%B8%B8%E5%AD%90%E5%90%9F.mp4",
            },
            {
                "bookName": "《鸟鸣涧》",
                "bookImg": require("../assets/poetry/grade5/鸟鸣涧.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E9%B8%9F%E9%B8%A3%E6%B6%A7.mp4",
            },
            {
                "bookName": "《从军行》",
                "bookImg": require("../assets/poetry/grade5/从军行.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E4%BB%8E%E5%86%9B%E8%A1%8C.mp4",
            },
            {
                "bookName": "《秋夜将晓出篱门迎凉有感》",
                "bookImg": require("../assets/poetry/grade5/秋夜将晓出篱门乘凉.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E7%A7%8B%E5%A4%9C%E5%B0%86%E6%99%93%E5%87%BA%E7%AF%B1%E9%97%A8%E8%BF%8E%E5%87%89%E6%9C%89%E6%84%9F.mp4",
            },
            {
                "bookName": "《凉州词》",
                "bookImg": require("../assets/poetry/grade5/凉州词.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E3%80%8A%E5%87%89%E5%B7%9E%E8%AF%8D%E3%80%8B.mp4",
            },
            {
                "bookName": "《闻军官收河南河北》",
                "bookImg": require("../assets/poetry/grade5/闻军官收河南河北.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E9%97%BB%E5%AE%98%E5%86%9B%E6%94%B6%E6%B2%B3%E5%8D%97%E6%B2%B3%E5%8C%97.mp4",
            },
            {
                "bookName": "《黄鹤楼送孟浩然之广陵》",
                "bookImg": require("../assets/poetry/grade5/黄鹤楼送孟浩然之广陵.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E9%BB%84%E9%B9%A4%E6%A5%BC%E9%80%81%E5%AD%9F%E6%B5%A9%E7%84%B6%E4%B9%8B%E5%B9%BF%E9%99%B5.mp4",
            },
            {
                "bookName": "《乡村四月》",
                "bookImg": require("../assets/poetry/grade5/乡村四月.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E4%BA%94%E5%B9%B4%E7%BA%A7/%E4%B9%A1%E6%9D%91%E5%9B%9B%E6%9C%88.mp4",
            },
        ]
    },
    {
        "gradeName": "六年级",
        "gradeCover": require("../assets/gradecover/6.png"),

        "gradeBooks": [
            {
                "bookName": "《宿建德江》",
                "bookImg": require("../assets/poetry/grade6/宿建德江.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E5%AE%BF%E5%BB%BA%E5%BE%B7%E6%B1%9F.mp4",
            },
            {
                "bookName": "《六月二十七日望湖楼醉书》",
                "bookImg": require("../assets/poetry/grade6/《六月二十七日望湖楼醉书》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E5%85%AD%E6%9C%88%E4%BA%8C%E5%8D%81%E4%B8%83%E6%97%A5%E6%9C%9B%E6%B9%96%E6%A5%BC%E9%86%89%E4%B9%A6.mp4",
            },
            {
                "bookName": "《西江月.夜行黄沙道中》",
                "bookImg": require("../assets/poetry/grade6/《西江月.夜行黄沙道中》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E8%A5%BF%E6%B1%9F%E6%9C%88.%E5%A4%9C%E8%A1%8C%E9%BB%84%E6%B2%99%E9%81%93%E4%B8%AD.mp4",
            },
            {
                "bookName": "《过故人庄》",
                "bookImg": require("../assets/poetry/grade6/过故人庄.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E3%80%8A%E8%BF%87%E6%95%85%E4%BA%BA%E5%BA%84%E3%80%8B.mp4",
            },
            {
                "bookName": "《春日》",
                "bookImg": require("../assets/poetry/grade6/春日.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E6%98%A5%E6%97%A5.mp4",
            },
            {
                "bookName": "《回乡偶书》",
                "bookImg": require("../assets/poetry/grade6/回乡偶书.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E5%9B%9E%E4%B9%A1%E5%81%B6%E4%B9%A6.mp4",
            },
            {
                "bookName": "《浪淘沙》（其一）",
                "bookImg": require("../assets/poetry/grade6/《浪淘沙》（其一）.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E6%B5%AA%E6%B7%98%E6%B2%99%E5%85%B6%E4%B8%80.mp4",
            },
            {
                "bookName": "《江南春》",
                "bookImg": require("../assets/poetry/grade6/江南春.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E6%B1%9F%E5%8D%97%E6%98%A5.mp4",
            },
            {
                "bookName": "《书湖阴先生壁》",
                "bookImg": require("../assets/poetry/grade6/《书湖阴先生壁》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E4%B9%A6%E6%B9%96%E9%98%B4%E5%85%88%E7%94%9F%E5%A3%81.mp4",
            },
            {
                "bookName": "《寒食》",
                "bookImg": require("../assets/poetry/grade6/寒食.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E5%AF%92%E9%A3%9F.mp4",
            },
            {
                "bookName": "《迢迢牵牛星》",
                "bookImg": require("../assets/poetry/grade6/迢迢牵牛星.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E3%80%8A%E8%BF%A2%E8%BF%A2%E7%89%B5%E7%89%9B%E6%98%9F%E3%80%8B.mp4",
            },
            {
                "bookName": "《十五夜望月》",
                "bookImg": require("../assets/poetry/grade6/十五夜望月.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E5%8D%81%E4%BA%94%E6%9C%88%E6%9C%9B%E6%9C%88.mp4",
            },
            {
                "bookName": "《马诗》",
                "bookImg": require("../assets/poetry/grade6/马诗.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E9%A9%AC%E8%AF%97.mp4",
            },
            {
                "bookName": "《长歌行》",
                "bookImg": require("../assets/poetry/grade6/长歌行.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E9%95%BF%E6%AD%8C%E8%A1%8C.mp4",
            },
            {
                "bookName": "《石灰吟》",
                "bookImg": require("../assets/poetry/grade6/石灰吟.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E7%9F%B3%E7%81%B0%E5%90%9F.mp4",
            },
            {
                "bookName": "《竹石》",
                "bookImg": require("../assets/poetry/grade6/竹石.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E7%AB%B9%E7%9F%B3.mp4",
            },
            {
                "bookName": "《采薇》（节选）",
                "bookImg": require("../assets/poetry/grade6/采薇.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E9%87%87%E8%96%87.mp4",
            },
            {
                "bookName": "《送元二使安西》",
                "bookImg": require("../assets/poetry/grade6/《送元二使安西》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E9%80%81%E5%85%83%E4%BA%8C%E4%BD%BF%E5%AE%89%E8%A5%BF.mp4",
            },
            {
                "bookName": "《早春呈水部张十八员外》",
                "bookImg": require("../assets/poetry/grade6/早春呈水部十八员外.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E6%97%A9%E6%98%A5%E5%91%88%E8%99%BD%E9%83%A8%E5%BC%A0%E5%8D%81%E5%85%AB%E5%91%98%E5%A4%96.mp4",
            },
            {
                "bookName": "《春夜喜雨》",
                "bookImg": require("../assets/poetry/grade6/春夜喜雨.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E6%98%A5%E5%A4%9C%E5%96%9C%E9%9B%A8.mp4",
            },
            {
                "bookName": "《江上渔者》",
                "bookImg": require("../assets/poetry/grade6/江上渔者.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E6%B1%9F%E4%B8%8A%E6%B8%94%E8%80%85.mp4",
            },
            {
                "bookName": "《泊船瓜洲》",
                "bookImg": require("../assets/poetry/grade6/泊船瓜洲.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E6%B3%8A%E8%88%B9%E7%93%9C%E6%B4%B2.mp4",
            },
            {
                "bookName": "《卜算子.送鲍浩然之浙东》",
                "bookImg": require("../assets/poetry/grade6/《卜算子.送鲍浩然之浙东》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E5%8D%9C%E7%AE%97%E5%AD%90.%E9%80%81%E9%B2%8D%E6%B5%A9%E7%84%B6%E4%B9%8B%E6%B5%99%E4%B8%9C.mp4",
            },
            {
                "bookName": "《浣溪沙》",
                "bookImg": require("../assets/poetry/grade6/浣溪沙.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E6%B5%A3%E6%BA%AA%E6%B2%99.mp4",
            },
            {
                "bookName": "《清平乐》",
                "bookImg": require("../assets/poetry/grade6/清平乐.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E6%B8%85%E5%B9%B3%E4%B9%90.mp4",
            },
            {
                "bookName": "《七律.长征》",
                "bookImg": require("../assets/poetry/grade6/《七律.长征》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E4%B8%83%E5%BE%8B.%E9%95%BF%E5%BE%81.mp4",
            },
            {
                "bookName": "《菩萨蛮.大柏地》",
                "bookImg": require("../assets/poetry/grade6/菩萨蛮大柏地.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E8%8F%A9%E8%90%A8%E8%9B%AE.%E5%A4%A7%E6%9F%8F%E5%9C%B0.mp4",
            },
            {
                "bookName": "《游园不值》",
                "bookImg": require("../assets/poetry/grade6/游园不值.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E5%85%AD%E5%B9%B4%E7%BA%A7/%E6%B8%B8%E5%9B%AD%E4%B8%8D%E5%80%BC.mp4",
            },
        ]
    },
    {
        "gradeName": "拓展",
        "gradeCover": require("../assets/gradecover/7.png"),
        "gradeBooks": [
            {
                "bookName": "《塞下曲》（其二）",
                "bookImg": require("../assets/poetry/extend/《塞下曲》（其二）.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E5%A1%9E%E4%B8%8B%E6%9B%B2%EF%BC%88%E5%85%B6%E4%BA%8C%EF%BC%89.mp4",
            },
            {
                "bookName": "《江畔独步寻花》（其六）",
                "bookImg": require("../assets/poetry/extend/《江畔独步寻花》（其六）.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%B1%9F%E7%95%94%E7%8B%AC%E6%AD%A5%E5%AF%BB%E8%8A%B1%EF%BC%88%E5%85%B6%E5%85%AD%EF%BC%89.mp4",
            },
            {
                "bookName": "《秋浦歌》（其十五）",
                "bookImg": require("../assets/poetry/extend/《秋浦歌》（其十五）.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E7%A7%8B%E6%B5%A6%E6%AD%8C%EF%BC%88%E5%85%B6%E5%8D%81%E4%BA%94%EF%BC%89.mp4",
            },
            {
                "bookName": "《商山早行》",
                "bookImg": require("../assets/poetry/extend/《商山早行》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E5%95%86%E5%B1%B1%E6%97%A9%E8%A1%8C.mp4",
            },
            {
                "bookName": "《赠花卿》",
                "bookImg": require("../assets/poetry/extend/赠花柳.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E8%B5%A0%E8%8A%B1%E5%8D%BF.mp4",
            },
            {
                "bookName": "《江南逢李龟年》",
                "bookImg": require("../assets/poetry/extend/江南缝李龟年.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%B1%9F%E5%8D%97%E9%80%A2%E6%9D%8E%E9%BE%9F%E5%B9%B4.mp4",
            },
            {
                "bookName": "《乌衣巷》",
                "bookImg": require("../assets/poetry/extend/乌衣巷.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E4%B9%8C%E8%A1%A3%E5%B7%B7.mp4",
            },
            {
                "bookName": "《秋夕》",
                "bookImg": require("../assets/poetry/extend/秋夕.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E7%A7%8B%E5%A4%95.mp4",
            },
            {
                "bookName": "《乐游原》",
                "bookImg": require("../assets/poetry/extend/乐游原.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E4%B9%90%E6%B8%B8%E5%8E%9F.mp4",
            },
            {
                "bookName": "《夜雨寄北》",
                "bookImg": require("../assets/poetry/extend/夜雨寄北.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E5%A4%9C%E9%9B%A8%E5%AF%84%E5%8C%97.mp4",
            },
            {
                "bookName": "《峨眉山月歌》",
                "bookImg": require("../assets/poetry/extend/峨眉山月歌.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E5%B3%A8%E7%9C%89%E5%B1%B1%E6%9C%88%E6%AD%8C.mp4",
            },
            {
                "bookName": "《春夜洛城闻笛》",
                "bookImg": require("../assets/poetry/extend/春夜洛城闻笛.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%98%A5%E5%A4%9C%E6%B4%9B%E5%9F%8E%E9%97%BB%E7%AC%9B.mp4",
            },
            {
                "bookName": "《闻王昌龄左迁龙标遥有此寄》",
                "bookImg": require("../assets/poetry/extend/《闻王昌龄左迁龙标遥有此寄》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E9%97%BB%E7%8E%8B%E6%98%8C%E9%BE%84%E5%B7%A6%E8%BF%81%E9%BE%99%E6%A0%87%E9%81%A5%E6%9C%89%E6%AD%A4%E5%AF%84.mp4",
            },
            {
                "bookName": "《天净沙.秋思》",
                "bookImg": require("../assets/poetry/extend/天净沙秋思.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E5%A4%A9%E5%87%80%E6%B2%99.%E7%A7%8B%E6%80%9D.mp4",
            },
            {
                "bookName": "《春望》",
                "bookImg": require("../assets/poetry/extend/春望.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%98%A5%E6%9C%9B.mp4",
            },
            {
                "bookName": "《如梦令》",
                "bookImg": require("../assets/poetry/extend/如梦令.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E5%A6%82%E6%A2%A6%E4%BB%A4.mp4",
            },
            {
                "bookName": "《野望》",
                "bookImg": require("../assets/poetry/extend/野望.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E9%87%8E%E6%9C%9B.mp4",
            },
            {
                "bookName": "《于易水送人》",
                "bookImg": require("../assets/poetry/extend/于易水送人.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E4%BA%8E%E6%98%93%E6%B0%B4%E9%80%81%E4%BA%BA.mp4",
            },
            {
                "bookName": "《山中》",
                "bookImg": require("../assets/poetry/extend/山中.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E5%B1%B1%E4%B8%AD.mp4",
            },
            {
                "bookName": "《送杜少府之任蜀州》",
                "bookImg": require("../assets/poetry/extend/《送杜少府之任蜀州》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E9%80%81%E6%9D%9C%E5%B0%91%E5%BA%9C%E4%B9%8B%E4%BB%BB%E8%9C%80%E5%B7%9E.mp4",
            },
            {
                "bookName": "《秋登万山寄张五》",
                "bookImg": require("../assets/poetry/extend/《秋登万山寄张五》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E7%A7%8B%E7%99%BB%E4%B8%87%E5%B1%B1%E5%AF%84%E5%BC%A0%E4%BA%94.mp4",
            },
            {
                "bookName": "《山中问答》",
                "bookImg": require("../assets/poetry/extend/山中问答.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E5%B1%B1%E4%B8%AD%E9%97%AE%E7%AD%94.mp4",
            },
            {
                "bookName": "《月下独酌》",
                "bookImg": require("../assets/poetry/extend/月下独酌.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%9C%88%E4%B8%8B%E7%8B%AC%E9%85%8C.mp4",
            },
            {
                "bookName": "《相思》",
                "bookImg": require("../assets/poetry/extend/相思.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E7%9B%B8%E6%80%9D.mp4",
            },
            {
                "bookName": "《山中送别》",
                "bookImg": require("../assets/poetry/extend/山中送别.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E5%B1%B1%E4%B8%AD%E9%80%81%E5%88%AB.mp4",
            },
            {
                "bookName": "《竹里馆》",
                "bookImg": require("../assets/poetry/extend/《竹里馆》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E7%AB%B9%E9%87%8C%E9%A6%86.mp4",
            },
            {
                "bookName": "《戏问花门酒家翁》",
                "bookImg": require("../assets/poetry/extend/《戏问花门酒家翁》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%88%8F%E9%97%AE%E8%8A%B1%E9%97%A8%E9%85%92%E5%AE%B6%E7%BF%81.mp4",
            },
            {
                "bookName": "《使至塞上》",
                "bookImg": require("../assets/poetry/extend/《使至塞上》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E4%BD%BF%E8%87%B3%E5%A1%9E%E4%B8%8A.mp4",
            },
            {
                "bookName": "《孤雁》",
                "bookImg": require("../assets/poetry/extend/孤雁.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E5%AD%A4%E9%9B%81.mp4",
            },
            {
                "bookName": "《月夜》",
                "bookImg": require("../assets/poetry/extend/月夜（短的）.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%9C%88%E5%A4%9C.mp4",
            },
            {
                "bookName": "《登岳阳楼》",
                "bookImg": require("../assets/poetry/extend/登岳阳楼.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E7%99%BB%E5%B2%B3%E9%98%B3%E6%A5%BC.mp4",
            },
            {
                "bookName": "《望岳》",
                "bookImg": require("../assets/poetry/extend/望岳.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%9C%9B%E5%B2%B3.mp4",
            },
            {
                "bookName": "《客至》",
                "bookImg": require("../assets/poetry/extend/客至.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E5%AE%A2%E8%87%B3.mp4",
            },
            {
                "bookName": "《旅夜书怀》",
                "bookImg": require("../assets/poetry/extend/《旅夜书怀》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%97%85%E5%A4%9C%E4%B9%A6%E6%80%80.mp4",
            },
            {
                "bookName": "《早梅》",
                "bookImg": require("../assets/poetry/extend/早梅.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%97%A9%E6%A2%85.mp4",
            },
            {
                "bookName": "《月夜》",
                "bookImg": require("../assets/poetry/extend/月夜（长的）.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%9C%88%E5%A4%9C-%E6%9D%9C%E7%94%AB.mp4",
            },
            {
                "bookName": "《秋词》",
                "bookImg": require("../assets/poetry/extend/秋词.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E7%A7%8B%E8%AF%8D.mp4",
            },
            {
                "bookName": "《夜雪》",
                "bookImg": require("../assets/poetry/extend/夜雪.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E5%A4%9C%E9%9B%AA.mp4",
            },
            {
                "bookName": "《钱塘湖春行》（节选）",
                "bookImg": require("../assets/poetry/extend/《钱塘湖春行》（节选）.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E9%92%B1%E5%A1%98%E6%B9%96%E6%98%A5%E8%A1%8C.mp4",
            },
            {
                "bookName": "《村夜》",
                "bookImg": require("../assets/poetry/extend/村夜.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%9D%91%E5%A4%9C.mp4",
            },
            {
                "bookName": "《泊秦淮》",
                "bookImg": require("../assets/poetry/extend/泊秦淮.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%B3%8A%E7%A7%A6%E6%B7%AE.mp4",
            },
            {
                "bookName": "《山亭夏日》",
                "bookImg": require("../assets/poetry/extend/山亭夏日.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E5%B1%B1%E4%BA%AD%E5%A4%8F%E6%97%A5.mp4",
            },
            {
                "bookName": "《社日》",
                "bookImg": require("../assets/poetry/extend/社日.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E7%A4%BE%E6%97%A5.mp4",
            },
            {
                "bookName": "《舟过安仁》",
                "bookImg": require("../assets/poetry/extend/《舟过安仁》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E8%88%9F%E8%BF%87%E5%AE%89%E4%BB%81.mp4",
            },
            {
                "bookName": "《登快阁》",
                "bookImg": require("../assets/poetry/extend/登快阁.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E7%99%BB%E5%BF%AB%E9%98%81.mp4",
            },
            {
                "bookName": "《游山西村》",
                "bookImg": require("../assets/poetry/extend/游山西村.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E6%B8%B8%E5%B1%B1%E8%A5%BF%E6%9D%91.mp4",
            },
            {
                "bookName": "《菩萨蛮.书江西造口壁》",
                "bookImg": require("../assets/poetry/extend/《菩萨蛮.书江西造口壁》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E8%8F%A9%E8%90%A8%E8%9B%AE.%E4%B9%A6%E6%B1%9F%E8%A5%BF%E9%80%A0%E5%8F%A3%E5%A3%81.mp4",
            },
            {
                "bookName": "《约客》",
                "bookImg": require("../assets/poetry/extend/约客.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E7%BA%A6%E5%AE%A2.mp4",
            },
            {
                "bookName": "《逢雪宿芙蓉山主人》",
                "bookImg": require("../assets/poetry/extend/《逢雪宿芙蓉山主人》.jpg"),
                "bookVideo": "http://h.dgyonglian.com/%E8%AF%97%E8%AF%8D%E5%B0%8F%E8%AE%B2%E5%9D%9B/%E6%8B%93%E5%B1%95/%E9%80%A2%E9%9B%AA%E5%AE%BF%E8%8A%99%E8%93%89%E5%B1%B1%E4%B8%BB%E4%BA%BA.mp4",
            },
        ]
    }
]